import Dexie from "dexie";

export interface Entity {
  id?: number;
  name?: string | null;
  object?: any | null;
}

export class LawDatabase extends Dexie {
  allText!: Dexie.Table<Entity, number>;
  definedWordsCitationPositions!: Dexie.Table<Entity, number>;
  definedWords!: Dexie.Table<Entity, number>;
  lawItemsCitationPositions!: Dexie.Table<Entity, number>;
  citationSources!: Dexie.Table<Entity, number>;

  constructor() {
    super("law-database");
    this.version(1).stores({
      allText: "++id,name,object",
      definedWordsCitationPositions: "++id,name,object",
      definedWords: "++id,name,object",
      lawItemsCitationPositions: "++id,name,object",
      citationSources: "++id,name,object",
    });
  }
}

export const db = new LawDatabase();
