import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { chapterObject, partObject } from "../interface/LawInterface";
import { kanji2number } from "@geolonia/japanese-numeral";

interface Props {
  lawChapters: chapterObject[];
  lawParts: partObject[];
}

export const LawViewIndex: React.VFC<Props> = ({ lawChapters, lawParts }) => {
  const kanjiToNumber = (title: string) => {
    const num =
      "([0-9０-９]*)|([〇一二三四五六七八九壱壹弐貳貮参參肆伍陸漆捌玖]*)";
    const basePattern = `((${num})(千|阡|仟))?((${num})(百|陌|佰))?((${num})(十|拾))?(${num})?`;
    const pattern = `((${basePattern}兆)?(${basePattern}億)?(${basePattern}(万|萬))?${basePattern})`;
    const regex = new RegExp(pattern, "g");
    const match = title.match(regex);
    match?.forEach((kanjiNumber) => {
      if (kanjiNumber) {
        title = title.replace(kanjiNumber, String(kanji2number(kanjiNumber)));
      }
    });
    return title;
  };

  const renderChapters = (chapter: chapterObject) => {
    return (
      <>
        <ListItem key={chapter.title} sx={{ my: 0, py: 0 }}>
          <ListItemButton
            sx={{ my: 0, py: 0 }}
            onClick={() => {
              window.location.href = `#${chapter.title}`;
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body2"
                  style={{
                    textIndent: "-2em",
                    paddingLeft: "2em",
                  }}
                >
                  第{chapter.num.replace("_", "-")}章{" "}
                  {chapter.title?.split("　")[1]}
                  {chapter.articles?.[0] && (
                    <>
                      （{kanjiToNumber(chapter.articles[0].title)}-
                      {kanjiToNumber(
                        chapter.articles[chapter.articles.length - 1].title
                      )}
                      ）
                    </>
                  )}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        {chapter.sections &&
          chapter.sections.map((section) => {
            return (
              <>
                <ListItem
                  key={section.title}
                  sx={{ ml: 3, mb: 0, mt: 0, pt: 0, pb: 0 }}
                >
                  <ListItemButton
                    sx={{ my: 0, py: 0 }}
                    onClick={() => {
                      window.location.href = `#${section.title}`;
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            textIndent: "-1em",
                            paddingLeft: "1em",
                          }}
                        >
                          第{section.num.replace("_", "-")}節{" "}
                          {section.title?.split("　")[1]}
                          {section.articles?.[0] && (
                            <>
                              （{kanjiToNumber(section.articles[0].title)}-
                              {kanjiToNumber(
                                section.articles[section.articles.length - 1]
                                  .title
                              )}
                              ）
                            </>
                          )}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {section.subsections &&
                  section.subsections.map((subSection) => {
                    return (
                      <ListItem
                        key={subSection.title}
                        sx={{ ml: 6, mb: 0, mt: 0, pt: 0, pb: 0 }}
                      >
                        <ListItemButton
                          sx={{ my: 0, py: 0 }}
                          onClick={() => {
                            window.location.href = `#${subSection.title}`;
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="body2"
                                style={{
                                  textIndent: "-1em",
                                  paddingLeft: "1em",
                                }}
                              >
                                第{subSection.num.replace("_", "-")}款{" "}
                                {subSection.title?.split("　")[1]}
                                {subSection.articles?.[0] && (
                                  <>
                                    （
                                    {kanjiToNumber(
                                      subSection.articles[0].title
                                    )}
                                    -
                                    {kanjiToNumber(
                                      subSection.articles[
                                        subSection.articles.length - 1
                                      ].title
                                    )}
                                    ）
                                  </>
                                )}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </>
            );
          })}
      </>
    );
  };

  return (
    <List>
      {lawChapters.map((chapter) => {
        return renderChapters(chapter);
      })}
      {lawParts.map((part) => {
        return (
          <>
            {part.chapters?.map((chapter) => {
              return renderChapters(chapter);
            })}
          </>
        );
      })}
    </List>
  );
};
