import React, { useState } from "react";
import {
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Paper,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { postLawSearch } from "../repositories/api";

export const SearchLaw: React.VFC = () => {
  const navigate = useNavigate();
  const [hitLawNames, setHitLawNames] = useState<string[]>([]);
  const [searchWord, setSearchWord] = useState("");
  const [showProgress, setShowProgress] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowProgress(true);
    const response = await postLawSearch(searchWord.replace("　", " "));
    setHitLawNames(response);
    setShowProgress(false);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  return (
    <>
      {showProgress && <LinearProgress />}
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
          }}
          id="searchLaw"
        >
          <Box sx={{ mb: 2 }}>
            <Typography component="h1" variant="h4">
              Boost 六法 法令検索
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              onChange={handleChange}
              sx={{
                width: "100%",
                border: "none",
                mb: 4,
              }}
              placeholder="法令検索"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {hitLawNames.length > 0 && (
              <>
                <Paper
                  variant="outlined"
                  sx={{
                    marginTop: 1,
                    width: "100%",
                    maxHeight: 460,
                    overflowY: "auto",
                  }}
                >
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {hitLawNames.map((value) => (
                      <ListItem key={value} disableGutters divider>
                        <ListItemButton
                          onClick={() => {
                            navigate(`/law/${value}`);
                          }}
                        >
                          <ListItemText primary={`${value}`} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
                <Typography variant="body1" sx={{ textAlign: "right", mt: 2 }}>
                  {hitLawNames.length}件
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};
