import React from "react";
import {
  itemObject,
  definedWordSauceObject,
  sentenceBodyObject,
  LinkListMap,
} from "../interface/LawInterface";
import { LawSentences } from "./LawSentences";
import { styled } from "@mui/material/styles";

interface Props {
  item: itemObject;
  plusMargin: number;
  linkList: LinkListMap;
  isSetLink?: boolean;
  definedWordSauces: definedWordSauceObject[];
  setToLawLinkDrawer: (
    sentenceBody: sentenceBodyObject,
    sourceBody?: string
  ) => Promise<void>;
  linkDrawerSourceBody: string;
  lawId?: string;
  autoWidth?: boolean;
}

const DT = styled("dt")(({ theme }) => ({
  fontWeight: "bold",
  float: "left",
  marginLeft: 0,
}));

export const LawItem: React.VFC<Props> = ({
  item,
  plusMargin = 0,
  linkList,
  isSetLink = true,
  definedWordSauces,
  setToLawLinkDrawer,
  linkDrawerSourceBody,
  lawId,
  autoWidth = false,
}) => {
  const marginWidth = autoWidth ? item.title.length : 1;
  return (
    <dl style={{ clear: "both", margin: 0, marginBottom: 8 }}>
      <DT style={{ minWidth: `${marginWidth + plusMargin}em` }}>
        {item.title}
      </DT>
      <dd style={{ marginLeft: `${marginWidth + plusMargin}em` }}>
        <LawSentences
          sentences={item.sentences}
          linkList={linkList}
          isSetLink={isSetLink}
          definedWordSauces={definedWordSauces}
          setToLawLinkDrawer={setToLawLinkDrawer}
          linkDrawerSourceBody={linkDrawerSourceBody}
          lawId={lawId}
        />
      </dd>
    </dl>
  );
};
