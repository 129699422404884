import React from "react";

interface Props {
  child: JSX.Element[];
}

export const HighLightWord: React.VFC<Props> = ({ child }) => {
  const hoverClassName = "sentenceParenthesesHover";
  return (
    <span
      className="inline"
      onMouseOver={(event) => {
        (event.currentTarget as Element).classList.add(hoverClassName);
        event.stopPropagation();
      }}
      onMouseOut={(event) => {
        (event.currentTarget as Element).classList.remove(hoverClassName);
        event.stopPropagation();
      }}
    >
      {child}
    </span>
  );
};
