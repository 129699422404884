import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { LawViewBody } from "../containers/LawViewBody";
import { LawViewIndex } from "../containers/LawViewIndex";
import { LawJumpSuggest } from "../containers/LawJumpSuggest";
import { LawSearch } from "../containers/LawSearch";
import {
  checkUpdate,
  getLawAllTextFromLawName,
  getLawId,
} from "../repositories/api";
import { db } from "../repositories/db";
import {
  chapterObject,
  paragraphObject,
  partObject,
} from "../interface/LawInterface";
const drawerWidth = 600;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const LawShowPage: React.VFC = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [lawChapters, setLawChapters] = useState<chapterObject[]>([]);
  const [lawPreamble, setLawPreamble] = useState<paragraphObject[]>([]);
  const [lawParts, setLawParts] = useState<partObject[]>([]);
  const [lawTitle, setLawTitle] = useState<string>("");
  const [lawId, setLawId] = useState<string>("");

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarIsOpen, setSnackbarIsOpen] = useState<boolean>(false);

  const params = useParams();
  const lawName = params.lawName ? params.lawName : "";
  const isDevelopMode = window.location.search.indexOf("?mode=develop") !== -1;

  const LawViewBodyMemo = useMemo(
    () => (
      <LawViewBody
        lawChapters={lawChapters}
        lawPreamble={lawPreamble}
        lawParts={lawParts}
        lawName={lawName}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarIsOpen={setSnackbarIsOpen}
        isDevelopMode={isDevelopMode}
        lawId={lawId}
      />
    ),
    [lawChapters, lawPreamble, lawParts]
  );
  const LawViewIndexMemo = useMemo(
    () => <LawViewIndex lawChapters={lawChapters} lawParts={lawParts} />,
    [lawChapters, lawParts]
  );
  const LawJumpSuggestMemo = useMemo(
    () => <LawJumpSuggest lawChapters={lawChapters} lawParts={lawParts} />,
    [lawChapters, lawParts]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchDialogOpen, setSearchDialogOpen] = React.useState(false);

  const searchDialogHandleClose = () => {
    setSearchDialogOpen(false);
  };

  const clearCache = () => {
    db.delete();
    handleClose();
    location.reload();
  };

  useEffect(() => {
    (async () => {
      if (await checkUpdate()) {
        clearCache();
      }
      setLawId(await getLawId(lawName));
      const lawAllText = await getLawAllTextFromLawName(lawName);
      setLawChapters(lawAllText.chapters);
      setLawTitle(lawAllText.title);
      if (lawAllText.preamble) {
        setLawPreamble(lawAllText.preamble);
      }
      if (lawAllText.parts) {
        setLawParts(lawAllText.parts);
      }
    })();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Snackbar
        open={snackbarIsOpen}
        message={snackbarMessage}
        action={<CircularProgress size={20} />}
      />
      <AppBar position="fixed">
        <Toolbar sx={{ pr: "24px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {lawTitle} - Boost 六法
          </Typography>
          {LawJumpSuggestMemo}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <IconButton
            size="large"
            aria-haspopup="true"
            onClick={() => {
              setSearchDialogOpen(true);
            }}
            color="inherit"
          >
            <SearchIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem
              onClick={() => {
                clearCache();
              }}
            >
              Clear Cache
            </MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Dialog
        open={searchDialogOpen}
        onClose={searchDialogHandleClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <LawSearch />
        </DialogContent>
      </Dialog>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <Divider />
        {LawViewIndexMemo}
      </Drawer>
      <Box p={4}>
        <DrawerHeader />
        {LawViewBodyMemo}
      </Box>
    </Box>
  );
};
