import React, { useEffect, createRef } from "react";
import { Typography, Box, Drawer, IconButton } from "@mui/material";
import {
  articleObject,
  definedWordSauceObject,
  sentenceBodyObject,
  LinkListMap,
} from "../../interface/LawInterface";
import { LawArticles } from "../../presenters/LawArticles";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface Props {
  linkArticleTitle: string;
  linkDrawerState: boolean;
  linkArticle: articleObject;
  linkList: LinkListMap;
  linkParagraphId: string;
  definedWordSauces: definedWordSauceObject[];
  setToLawLinkDrawer: (
    sentenceBody: sentenceBodyObject,
    sourceBody?: string
  ) => Promise<void>;
  setLinkDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  linkDrawerSourceBody: string;
}

export const LinkDrawer: React.VFC<Props> = ({
  linkArticleTitle,
  linkDrawerState,
  linkArticle,
  linkList,
  linkParagraphId,
  definedWordSauces,
  setToLawLinkDrawer,
  setLinkDrawerState,
  linkDrawerSourceBody,
}) => {
  const ref = createRef<HTMLDivElement>();

  const openNewWindow = (name = linkArticleTitle, id?: string) => {
    let path = `/law/${name}`;
    if (id) {
      path += `#${id}`;
    }
    window.open(path);
  };

  const setScroll = () => {
    if (ref.current && linkParagraphId !== "") {
      const scrollWrap = ref.current.firstElementChild;
      if (scrollWrap) {
        const targetBox = scrollWrap.querySelector(
          `[data-paragraph_id="${linkParagraphId}"]`
        );
        if (targetBox && linkParagraphId !== "1") {
          scrollWrap.scrollTo(0, (targetBox as HTMLElement).offsetTop - 16);
        } else {
          scrollWrap.scrollTo(0, 0);
        }
      }
    }
  };

  useEffect(() => {
    setScroll();
  }, [linkArticle, linkList, linkParagraphId, ref]);

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={linkDrawerState}
      ref={ref}
    >
      {linkArticle && linkList && (
        <Box
          pt={4}
          px={4}
          sx={{
            top: "60%",
          }}
        >
          <Typography variant="h6" paragraph>
            {linkArticleTitle} {linkArticle.title} {linkArticle.caption}
          </Typography>
          <IconButton
            aria-label="new-window"
            sx={{
              position: "fixed",
              right: 50,
              top: "calc(50% + 16px)",
              backgroundColor: "#FFF",
            }}
            onClick={() =>
              openNewWindow(
                linkArticleTitle,
                `${linkArticle.title}${linkArticle.caption}`
              )
            }
          >
            <OpenInNewIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            sx={{
              position: "fixed",
              right: 10,
              top: "calc(50% + 16px)",
              backgroundColor: "#FFF",
            }}
            onClick={() => setLinkDrawerState(false)}
          >
            <CloseIcon />
          </IconButton>
          <LawArticles
            article={linkArticle}
            paragraphs={linkArticle.paragraphs}
            isSetLink={false}
            linkList={linkList}
            definedWordSauces={definedWordSauces}
            setToLawLinkDrawer={setToLawLinkDrawer}
            linkParagraphId={linkParagraphId}
            linkDrawerSourceBody={linkDrawerSourceBody}
          />
        </Box>
      )}
    </Drawer>
  );
};
