import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

interface Props {
  value: any;
  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void;
}

export const MultipleLinkSelection: React.VFC<Props> = ({
  value,
  onChange,
}) => {
  return (
    <ToggleButtonGroup value={value} onChange={onChange} size="small">
      <ToggleButton value="定義語">定義語</ToggleButton>
      <ToggleButton value="参照">参照</ToggleButton>
    </ToggleButtonGroup>
  );
};
