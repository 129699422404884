import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { postLawSearch } from "../repositories/api";

export const LawSearch: React.VFC = () => {
  const [hitLawNames, setHitLawNames] = useState<string[]>([]);
  const [searchWord, setSearchWord] = useState("");
  const [showProgress, setShowProgress] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowProgress(true);
    const response = await postLawSearch(searchWord.replace("　", " "));
    setHitLawNames(response);
    setShowProgress(false);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  return (
    <>
      {showProgress && <LinearProgress />}
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
        }}
        id="searchLaw"
      >
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            fullWidth
            autoFocus
            onChange={handleChange}
            sx={{
              width: "100%",
              border: "none",
            }}
            placeholder="法令検索"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {hitLawNames.length > 0 && (
            <>
              <Typography variant="body1" sx={{ textAlign: "right", mt: 2 }}>
                {hitLawNames.length}件
              </Typography>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {hitLawNames.map((value) => (
                  <ListItem key={value} disableGutters divider>
                    <ListItemButton
                      onClick={() => {
                        location.href = `/law/${value}`;
                      }}
                    >
                      <ListItemText primary={`${value}`} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
