import * as React from "react";
import ReactDOM from "react-dom";
import { GlobalStyle } from "./config/globalStyle";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LawShowPage } from "./pages/LawShowPage";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { Forget } from "./pages/Forget";
import { SearchLaw } from "./pages/SearchLaw";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import theme from "./config/theme";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e5f8dcdd4dcf4a73a754c4e3cf0cd0a4@o1128410.ingest.sentry.io/6192408",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path="law" element={<LawShowPage />}>
          <Route path=":lawName" element={<LawShowPage />} />
        </Route>
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="forget" element={<Forget />} />
        <Route path="/" element={<SearchLaw />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.querySelector("#root")
);
