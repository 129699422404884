/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
  articleObject,
  chapterObject,
  citationLinkObject,
  citationPositionObject,
  definedWordsObject,
  definedWordSauceObject,
  definedWordLinkObject,
  paragraphObject,
  partObject,
  citationSourcesListObject,
  citationSourcesObject,
  sentenceBodyObject,
  LinkListMap,
} from "../interface/LawInterface";
import { LawArticles } from "../presenters/LawArticles";
import { CitationSourcesDrawer } from "../presenters/Drawer/CitationSourcesDrawer";
import { LinkDrawer } from "../presenters/Drawer/LinkDrawer";
import { MultipleLinkSelection } from "../presenters/MultipleLinkSelection";
import {
  getCitationLinks,
  getDefinedWordLinks,
  getDefinedWordSauces,
  getCitationSources,
  getToLawArticle,
} from "../repositories/api";
import {
  formattingCitationLinks,
  formattingDefinedWordLinks,
} from "../repositories/Formatting";

interface Props {
  lawChapters: chapterObject[];
  lawPreamble: paragraphObject[];
  lawParts: partObject[];
  lawName: string;
  lawId: string;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDevelopMode: boolean;
}

export const LawViewBody: React.VFC<Props> = ({
  lawChapters,
  lawPreamble,
  lawParts,
  lawName,
  lawId,
  setSnackbarMessage,
  setSnackbarIsOpen,
  isDevelopMode,
}) => {
  const [linkDrawerState, setLinkDrawerState] = useState(false);
  const [linkDrawerParagraphId, setLinkDrawerParagraphId] = useState("");
  const [linkDrawerSourceBody, setLinkDrawerSourceBody] = useState("");
  const [linkArticle, setLinkArticle] = useState<articleObject>({
    num: "",
    title: "",
    caption: "",
  });
  const [linkArticleTitle, setLinkArticleTitle] = useState<string>("");
  const [citationSourcesDrawerState, setCitationSourcesDrawerState] =
    useState(false);
  const [sourcesArticle, setSourcesArticle] = useState<string>("");
  const [, setSourcesParagraph] = useState<string>("");
  const [linkList, setLinkList] = useState<LinkListMap>(new Map());

  const [definedWordSauces, setDefinedWordSauces] = useState<
    definedWordSauceObject[]
  >([]);
  const [citationSources, setCitationSources] =
    useState<citationSourcesListObject>({
      law_id: 0,
      article_num: {},
    });
  const [, setLowId] = useState<number>(0);

  const setToLawLinkDrawer = async (
    sentenceBody: sentenceBodyObject,
    sourceBody?: string
  ) => {
    const targetToLaw = await getToLawArticle(sentenceBody);
    setLinkArticle(targetToLaw.article);
    setLinkArticleTitle(targetToLaw.title);
    setLinkDrawerSourceBody(sourceBody || "");
    if (sentenceBody.to_paragraph_id) {
      setLinkDrawerParagraphId(sentenceBody.to_paragraph_id);
    }
    setLinkDrawerState(true);
  };

  const [formats, setFormats] = React.useState(() => ["定義語", "参照"]);
  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    setFormats(newFormats);
  };

  const renderArticles = (articles: articleObject[]) => {
    return articles.map((article, index) => (
      <LawArticles
        key={`${article.title}_${index}`}
        article={article}
        linkList={linkList}
        definedWordSauces={definedWordSauces}
        setCitationSourcesDrawerState={setCitationSourcesDrawerState}
        setSourcesArticle={setSourcesArticle}
        setSourcesParagraph={setSourcesParagraph}
        addCitationSources={addCitationSources}
        setToLawLinkDrawer={setToLawLinkDrawer}
        linkDrawerSourceBody={linkDrawerSourceBody}
        lawId={lawId}
      />
    ));
  };

  const hashScroll = (id: string) => {
    const target = window.document.getElementById(id);
    if (target) {
      target.scrollIntoView();
    }
  };

  const renderChapters = (chapters: chapterObject[]) => {
    return chapters.map((chapter) => {
      return (
        <Box key={chapter.title}>
          <Typography variant="h6" sx={{ mb: 1 }} id={chapter.title} paragraph>
            {chapter.title}
          </Typography>
          {chapter.sections &&
            chapter.sections.map((section) => {
              return (
                <Box key={section.title}>
                  <Typography
                    variant="h6"
                    sx={{ mb: 1 }}
                    paragraph
                    id={section.title}
                  >
                    {section.title}
                  </Typography>
                  {section.subsections &&
                    section.subsections.map((subsection) => {
                      return (
                        <Box key={subsection.title}>
                          <Typography
                            variant="h6"
                            paragraph
                            id={subsection.title}
                          >
                            {subsection.title}
                          </Typography>
                          {subsection.divisions &&
                            subsection.divisions.map((division) => {
                              return (
                                <Box key={division.title}>
                                  <Typography
                                    variant="h6"
                                    paragraph
                                    id={division.title}
                                  >
                                    {division.title}
                                  </Typography>
                                  {division.articles &&
                                    renderArticles(division.articles)}
                                </Box>
                              );
                            })}
                          {subsection.articles &&
                            renderArticles(subsection.articles)}
                        </Box>
                      );
                    })}
                  {section.articles && renderArticles(section.articles)}
                </Box>
              );
            })}
          {chapter.articles && renderArticles(chapter.articles)}
        </Box>
      );
    });
  };

  const pushDefinedWords = (
    itemsCitationPositions: citationLinkObject[],
    definedWordPositions: definedWordLinkObject[]
  ): citationLinkObject[] => {
    const allPathList = itemsCitationPositions
      .map((v) => v.path)
      .concat(definedWordPositions.map((v) => v.path));
    const uniqPathList = Array.from(new Set(allPathList)).sort();

    uniqPathList.forEach((path) => {
      const targetItemsCitationPosition = itemsCitationPositions.find(
        (v) => v.path === path
      );
      const targetDefinedWordPosition = definedWordPositions.find(
        (v) => v.path === path
      );
      if (targetItemsCitationPosition && targetDefinedWordPosition) {
        targetDefinedWordPosition.definedWords?.forEach(
          (definedWord: definedWordsObject) => {
            targetItemsCitationPosition.citations?.push({
              offset: definedWord.offset,
              length: definedWord.length,
              defined_word_id: definedWord.defined_word_id,
            });
          }
        );
        if (targetItemsCitationPosition.citations) {
          targetItemsCitationPosition.citations =
            targetItemsCitationPosition.citations.sort((a: any, b: any) => {
              if (a.offset < b.offset) return -1;
              if (a.offset > b.offset) return 1;
              return 0;
            });
          // offsetの重複を削除
          const offsetList = targetItemsCitationPosition.citations.map(
            (item) => {
              return item.offset;
            }
          );
          const offsetSet = new Set(offsetList);
          const uniqueOffset = Array.from(offsetSet);
          if (
            uniqueOffset.length !== targetItemsCitationPosition.citations.length
          ) {
            targetItemsCitationPosition.citations = uniqueOffset.map(
              (offset) => {
                const offsetArray =
                  targetItemsCitationPosition.citations?.filter((item) => {
                    return item.offset === offset;
                  });
                if (offsetArray) {
                  return offsetArray[0];
                }
                return {
                  offset: 0,
                  length: 0,
                  defined_word_id: 0,
                };
              }
            );
          }
        }
      } else if (targetDefinedWordPosition) {
        itemsCitationPositions.push({
          path: path,
          citations: [],
        });
        const targetItemsCitationPosition = itemsCitationPositions.slice(-1)[0];
        targetDefinedWordPosition.definedWords?.forEach(
          (definedWord: definedWordsObject) => {
            targetItemsCitationPosition.citations?.push({
              offset: definedWord.offset,
              length: definedWord.length,
              defined_word_id: definedWord.defined_word_id,
            });
          }
        );
      }
    });
    return itemsCitationPositions;
  };

  const isEmpty = (obj: any): boolean => {
    return !Object.keys(obj).length;
  };

  const addCitationSources = async (addArticleNum: string) => {
    const addCitationSources = await getCitationSources(
      lawName,
      "0",
      addArticleNum
    );

    if (
      addCitationSources.article_num &&
      addCitationSources.article_num[addArticleNum]
    ) {
      citationSources.article_num = {
        ...citationSources.article_num,
        ...addCitationSources.article_num,
      };
      setCitationSources(citationSources);
    }
  };

  useEffect(() => {
    (async () => {
      setSnackbarIsOpen(true);
      setSnackbarMessage("参照情報 セット中");
      setTimeout(() => {
        Promise.all([getDefinedWordLinks(lawName), getCitationLinks(lawName)])
          .then(async (results) => {
            const lawDefinedWordPositions: citationPositionObject = results[0];
            const lawItemsCitationPositions: citationPositionObject =
              results[1];
            setLowId(lawItemsCitationPositions.law_id);
            hashScroll(decodeURI(window.location.hash.replace("#", "")));

            if (
              !isEmpty(lawItemsCitationPositions) &&
              !isEmpty(lawDefinedWordPositions)
            ) {
              const itemsCitationPositions = formattingCitationLinks(
                lawItemsCitationPositions.article_num
              );
              const definedWordPositions = formattingDefinedWordLinks(
                lawDefinedWordPositions.article_num
              );

              let citationAndDefinedWord: citationLinkObject[] = [];
              if (formats.length === 2) {
                citationAndDefinedWord = pushDefinedWords(
                  itemsCitationPositions,
                  definedWordPositions
                );
              } else if (formats.length === 1) {
                if (formats.indexOf("参照") === 1) {
                  citationAndDefinedWord = pushDefinedWords(
                    [],
                    definedWordPositions
                  );
                } else {
                  citationAndDefinedWord = pushDefinedWords(
                    itemsCitationPositions,
                    []
                  );
                }
              }

              const linkListMap = new Map();
              citationAndDefinedWord.forEach((obj: citationLinkObject) => {
                linkListMap.set(obj.path, obj);
              });
              setLinkList(linkListMap);

              setDefinedWordSauces(await getDefinedWordSauces(lawName));
              setSnackbarIsOpen(false);
            }
          })
          .catch((reject) => {
            setSnackbarMessage("参照情報の取得に失敗しました");
          });
      }, 200);
    })();
  }, [formats]);

  return (
    <React.Fragment>
      <>
        {/* 前文 */}
        {lawPreamble &&
          lawPreamble.map((paragraph) => (
            <>
              {paragraph.sentences &&
                paragraph.sentences.map((sentence, index) => (
                  <Typography
                    sx={{ mb: 2 }}
                    key={sentence.path.join(`${index}`)}
                  >
                    {sentence.body}
                  </Typography>
                ))}
            </>
          ))}
        {lawParts &&
          linkList &&
          definedWordSauces &&
          citationSources &&
          lawParts.map((part) => (
            <Box key={part.title}>
              <Typography variant="h6" sx={{ mb: 1 }} id={part.title} paragraph>
                {part.title}
              </Typography>
              {part.chapters && renderChapters(part.chapters)}
            </Box>
          ))}
        {lawChapters && linkList && renderChapters(lawChapters)}
      </>
      {isDevelopMode && (
        <Box
          sx={{
            position: "fixed",
            top: 100,
            right: 10,
            zIndex: 9999,
            backgroundColor: "#FFF",
          }}
        >
          <MultipleLinkSelection value={formats} onChange={handleFormat} />
        </Box>
      )}
      <LinkDrawer
        linkArticleTitle={linkArticleTitle}
        linkDrawerState={linkDrawerState}
        linkArticle={linkArticle}
        linkList={linkList}
        linkParagraphId={linkDrawerParagraphId}
        definedWordSauces={definedWordSauces}
        linkDrawerSourceBody={linkDrawerSourceBody}
        setLinkDrawerState={setLinkDrawerState}
        setToLawLinkDrawer={setToLawLinkDrawer}
      />
      {sourcesArticle in citationSources.article_num && (
        <CitationSourcesDrawer
          citationSourcesDrawerState={citationSourcesDrawerState}
          sourcesArticle={sourcesArticle}
          citationSources={citationSources}
          linkArticleTitle={linkArticleTitle}
          setCitationSourcesDrawerState={setCitationSourcesDrawerState}
        />
      )}
    </React.Fragment>
  );
};
