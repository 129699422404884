/* eslint-disable camelcase */
import React, { Dispatch, SetStateAction, useMemo } from "react";
import {
  paragraphObject,
  // citationLinkObject,
  articleObject,
  definedWordSauceObject,
  sentenceBodyObject,
  LinkListMap,
} from "../interface/LawInterface";
import { LawSentences } from "./LawSentences";
import { LawArticlesTitle } from "./LawArticlesTitle";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";
import { LawItem } from "./LawItem";

import { useInView } from "react-intersection-observer";

interface Props {
  article: articleObject;
  title?: string;
  caption?: string;
  paragraphs?: paragraphObject[];
  isSetLink?: boolean;
  linkList: LinkListMap;
  definedWordSauces: definedWordSauceObject[];
  setCitationSourcesDrawerState?: Dispatch<SetStateAction<boolean>>;
  setSourcesArticle?: Dispatch<SetStateAction<string>>;
  setSourcesParagraph?: Dispatch<SetStateAction<string>>;
  addCitationSources?: (addArticleNum: string) => Promise<void>;
  setToLawLinkDrawer: (
    sentenceBody: sentenceBodyObject,
    sourceBody?: string
  ) => Promise<void>;
  linkParagraphId?: string;
  linkDrawerSourceBody: string;
  lawId?: string;
}

export const LawArticles: React.VFC<Props> = ({
  article,
  linkList,
  isSetLink = true,
  definedWordSauces,
  setCitationSourcesDrawerState,
  setSourcesArticle,
  setSourcesParagraph,
  addCitationSources,
  setToLawLinkDrawer,
  linkParagraphId,
  linkDrawerSourceBody,
  lawId,
}) => {
  const { ref, inView } = useInView({
    rootMargin: "500px",
    triggerOnce: true,
  });

  return useMemo(() => {
    return (
      <Box sx={{ mb: 4 }} ref={ref}>
        {article.title && (
          <LawArticlesTitle
            key={article.title}
            articleNum={article.num}
            title={`${article.title}${article.caption}`}
            isSetLink={isSetLink}
            setCitationSourcesDrawerState={setCitationSourcesDrawerState}
            setSourcesArticle={setSourcesArticle}
            setSourcesParagraph={setSourcesParagraph}
            addCitationSources={addCitationSources}
          />
        )}
        <Box sx={{ mb: 2, borderBottom: 1, borderColor: grey[200] }}>
          {article.paragraphs &&
            article.paragraphs.map(({ num, sentences, items, path }) => (
              // 1, 2, 3
              <Box
                sx={{ mb: 2 }}
                id={`${path}`}
                key={`${path.join("_")}`}
                data-paragraph_id={`${num}`}
              >
                {inView && (
                  <>
                    {Number(num) === 1 && article.paragraphs?.length === 1 ? (
                      <Box sx={{ mb: 2, mt: 2 }}>
                        <LawSentences
                          sentences={sentences}
                          linkList={linkList}
                          isSetLink={isSetLink}
                          definedWordSauces={definedWordSauces}
                          setToLawLinkDrawer={setToLawLinkDrawer}
                          linkDrawerSourceBody={
                            linkParagraphId === num && !isSetLink
                              ? linkDrawerSourceBody
                              : ""
                          }
                          lawId={lawId}
                        />
                      </Box>
                    ) : (
                      <>
                        <LawItem
                          item={{
                            num: "",
                            path: [],
                            title: num,
                            sentences: sentences,
                          }}
                          plusMargin={1}
                          linkList={linkList}
                          isSetLink={isSetLink}
                          definedWordSauces={definedWordSauces}
                          setToLawLinkDrawer={setToLawLinkDrawer}
                          linkDrawerSourceBody={
                            linkParagraphId === num && !isSetLink
                              ? linkDrawerSourceBody
                              : ""
                          }
                          lawId={lawId}
                        />
                      </>
                    )}
                    {items &&
                      items.map((item) => (
                        // 一, 二, 三
                        <Box sx={{ ml: 5, mb: 1 }} key={item.title}>
                          <LawItem
                            item={item}
                            plusMargin={1}
                            linkList={linkList}
                            isSetLink={isSetLink}
                            definedWordSauces={definedWordSauces}
                            setToLawLinkDrawer={setToLawLinkDrawer}
                            linkDrawerSourceBody={
                              linkParagraphId === num && !isSetLink
                                ? linkDrawerSourceBody
                                : ""
                            }
                            lawId={lawId}
                            autoWidth={true}
                          />
                          {item.subItems &&
                            item.subItems.map((secondNestItem) => (
                              // イ, ロ, ハ
                              <Box sx={{ ml: 5 }} key={secondNestItem.title}>
                                <LawItem
                                  item={secondNestItem}
                                  plusMargin={1}
                                  linkList={linkList}
                                  isSetLink={isSetLink}
                                  definedWordSauces={definedWordSauces}
                                  setToLawLinkDrawer={setToLawLinkDrawer}
                                  linkDrawerSourceBody={
                                    linkParagraphId === num && !isSetLink
                                      ? linkDrawerSourceBody
                                      : ""
                                  }
                                  lawId={lawId}
                                />
                                {secondNestItem.subItems &&
                                  secondNestItem.subItems.map(
                                    (thirdNestItems) => (
                                      // ( 1, 2, 3 )
                                      <Box
                                        sx={{ ml: 4 }}
                                        key={thirdNestItems.title}
                                      >
                                        <LawItem
                                          item={thirdNestItems}
                                          plusMargin={2}
                                          linkList={linkList}
                                          isSetLink={isSetLink}
                                          definedWordSauces={definedWordSauces}
                                          setToLawLinkDrawer={
                                            setToLawLinkDrawer
                                          }
                                          linkDrawerSourceBody={
                                            linkParagraphId === num &&
                                            !isSetLink
                                              ? linkDrawerSourceBody
                                              : ""
                                          }
                                          lawId={lawId}
                                        />
                                        {thirdNestItems.subItems &&
                                          thirdNestItems.subItems.map(
                                            (fourthNestItem) => (
                                              <Box
                                                sx={{ ml: 5 }}
                                                key={fourthNestItem.title}
                                              >
                                                <LawItem
                                                  item={fourthNestItem}
                                                  plusMargin={2.5}
                                                  linkList={linkList}
                                                  isSetLink={isSetLink}
                                                  definedWordSauces={
                                                    definedWordSauces
                                                  }
                                                  setToLawLinkDrawer={
                                                    setToLawLinkDrawer
                                                  }
                                                  linkDrawerSourceBody={
                                                    linkParagraphId === num &&
                                                    !isSetLink
                                                      ? linkDrawerSourceBody
                                                      : ""
                                                  }
                                                  lawId={lawId}
                                                />
                                              </Box>
                                            )
                                          )}
                                      </Box>
                                    )
                                  )}
                              </Box>
                            ))}
                        </Box>
                      ))}
                  </>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    );
  }, [
    article,
    linkList,
    definedWordSauces,
    linkDrawerSourceBody,
    linkParagraphId,
    inView,
  ]);
};
