/* eslint-disable no-labels */
/* eslint-disable camelcase */
import XMLParser from "xml2js";
import {
  paragraphObject,
  itemObject,
  sentenceObject,
  articleObject,
  divisionObject,
  sectionObject,
  chapterObject,
  definedWordLinkObject,
  citationLinkObject,
  partObject,
  sentenceBodyObject,
  sentenceBodyObjectHierarchy,
  LinkListMap,
} from "../interface/LawInterface";

const getSentenceObject = (
  item: any,
  parentPath: number[]
): sentenceObject[] => {
  const resultSentences = item.ItemSentence
    ? item.ItemSentence[0].Column
      ? item.ItemSentence[0].Column.map((column: any) => {
          const sentenceObject: sentenceObject[] = column.Sentence
            ? column.Sentence.map((sentence: any, index: number) => {
                return {
                  num: `${index}`,
                  path: [...parentPath, index],
                  body: sentence.$ ? sentence._ : sentence,
                };
              })
            : [];
          return sentenceObject;
        })
      : item.ItemSentence[0].Sentence.map((sentence: any, index: number) => {
          const sentenceObject: sentenceObject = {
            num: `${index}`,
            path: [...parentPath, index],
            body: sentence.$ ? sentence._ : sentence,
          };
          return sentenceObject;
        })
    : [];
  return resultSentences;
};

const getArticlesObject = (articles: any): articleObject[] => {
  return articles.map((article: any) => {
    const articleObject: articleObject = {
      num: article.$.Num,
      caption: article.ArticleCaption ? article.ArticleCaption[0] : "",
      title: article.ArticleTitle ? article.ArticleTitle[0] : "",
      paragraphs: article.Paragraph
        ? article.Paragraph.map((paragraph: any) => {
            const paragraphObject: paragraphObject = {
              num: paragraph.$.Num,
              path: [article.$.Num, paragraph.$.Num],
              sentences: paragraph.ParagraphSentence
                ? paragraph.ParagraphSentence.map((column: any) => {
                    const sentenceObject: sentenceObject[] = column.Sentence
                      ? column.Sentence.map((sentence: any, index: number) => {
                          return {
                            num: `${index}`,
                            path: [article.$.Num, paragraph.$.Num, index],
                            body: sentence.$ ? sentence._ : sentence,
                          };
                        })
                      : [];
                    return sentenceObject;
                  })
                : [],
              items: paragraph.Item
                ? paragraph.Item.map((item: any) => {
                    const path = [article.$.Num, paragraph.$.Num, item.$.Num];
                    const itemObject: itemObject = {
                      num: item.$.Num,
                      path: path,
                      title: item.ItemTitle ? item.ItemTitle[0] : "",
                      sentences: getSentenceObject(item, path),
                      subItems: item.Subitem1
                        ? item.Subitem1.map((subitem1: any) => {
                            const path = [
                              article.$.Num,
                              paragraph.$.Num,
                              item.$.Num,
                              subitem1.$.Num,
                            ];
                            const itemObject: itemObject = {
                              num: subitem1.$.Num,
                              path: path,
                              title: subitem1.Subitem1Title,
                              sentences: getSentenceObject(subitem1, path),
                              subItems: subitem1.Subitem2
                                ? subitem1.Subitem2.map((subitem2: any) => {
                                    const path = [
                                      article.$.Num,
                                      paragraph.$.Num,
                                      item.$.Num,
                                      subitem1.$.Num,
                                      subitem2.$.Num,
                                    ];
                                    const itemObject: itemObject = {
                                      num: subitem2.$.Num,
                                      path: path,
                                      title: subitem2.Subitem2Title,
                                      sentences: getSentenceObject(
                                        subitem2,
                                        path
                                      ),
                                      subItems: subitem2.Subitem3
                                        ? subitem2.Subitem3.map(
                                            (subitem3: any) => {
                                              const path = [
                                                article.$.Num,
                                                paragraph.$.Num,
                                                item.$.Num,
                                                subitem1.$.Num,
                                                subitem2.$.Num,
                                                subitem3.$.Num,
                                              ];
                                              const itemObject: itemObject = {
                                                num: subitem3.$.Num,
                                                path: path,
                                                title: subitem3.Subitem3Title,
                                                sentences: getSentenceObject(
                                                  subitem3,
                                                  path
                                                ),
                                              };
                                              return itemObject;
                                            }
                                          )
                                        : [],
                                    };
                                    return itemObject;
                                  })
                                : [],
                            };
                            return itemObject;
                          })
                        : [],
                    };
                    return itemObject;
                  })
                : [],
            };
            return paragraphObject;
          })
        : [],
    };
    return articleObject;
  });
};

type formatLaw = {
  title: string;
  chapters: chapterObject[];
  parts: partObject[];
  preamble?: paragraphObject[];
};

export const getTargetArticles = (
  sourceLaw: formatLaw,
  articleNum: string | undefined
): articleObject => {
  let targetArticles: articleObject = {
    num: "",
    title: "",
    caption: "",
  };
  if (sourceLaw.chapters.length !== 0) {
    const chapters = sourceLaw.chapters;
    if (chapters) {
      const articles = chapters.flatMap((v: sectionObject) => v.articles);
      const target = articles.find(
        (v: articleObject | undefined) => v?.num === articleNum
      );
      if (target) {
        targetArticles = target;
      } else {
        grid_loop: for (const chapter of chapters) {
          if (chapter.sections) {
            const articles = chapter.sections.flatMap(
              (v: sectionObject) => v.articles
            );
            const target = articles.find(
              (v: articleObject | undefined) => v?.num === articleNum
            );
            if (target) {
              targetArticles = target;
              break grid_loop;
            } else {
              for (const section of chapter.sections) {
                if (section.subsections) {
                  const articles = section.subsections.flatMap(
                    (v: sectionObject) => v.articles
                  );
                  const target = articles.find(
                    (v: articleObject | undefined) => v?.num === articleNum
                  );
                  if (target) {
                    targetArticles = target;
                    break grid_loop;
                  } else {
                    for (const subsection of section.subsections) {
                      if (subsection.divisions) {
                        const articles = subsection.divisions.flatMap(
                          (v: sectionObject) => v.articles
                        );
                        const target = articles.find(
                          (v: articleObject | undefined) =>
                            v?.num === articleNum
                        );
                        if (target) {
                          targetArticles = target;
                          break grid_loop;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } else if (sourceLaw.parts.length !== 0) {
    grid_loop: for (const part of sourceLaw.parts) {
      const chapters = part.chapters;
      if (chapters) {
        const articles = chapters.flatMap((v: sectionObject) => v.articles);
        const target = articles.find(
          (v: articleObject | undefined) => v?.num === articleNum
        );
        if (target) {
          targetArticles = target;
          break;
        } else {
          for (const chapter of chapters) {
            if (chapter.sections) {
              const articles = chapter.sections.flatMap(
                (v: sectionObject) => v.articles
              );
              const target = articles.find(
                (v: articleObject | undefined) => v?.num === articleNum
              );
              if (target) {
                targetArticles = target;
                break grid_loop;
              } else {
                for (const section of chapter.sections) {
                  if (section.subsections) {
                    const articles = section.subsections.flatMap(
                      (v: sectionObject) => v.articles
                    );
                    const target = articles.find(
                      (v: articleObject | undefined) => v?.num === articleNum
                    );
                    if (target) {
                      targetArticles = target;
                      break grid_loop;
                    } else {
                      for (const subsection of section.subsections) {
                        if (subsection.divisions) {
                          const articles = subsection.divisions.flatMap(
                            (v: sectionObject) => v.articles
                          );
                          const target = articles.find(
                            (v: articleObject | undefined) =>
                              v?.num === articleNum
                          );
                          if (target) {
                            targetArticles = target;
                            break grid_loop;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return targetArticles;
};

const formattingParagraph = (paragraphs: paragraphObject[]) => {
  return paragraphs.map((paragraph: any) => {
    const paragraphObject: paragraphObject = {
      num: paragraph.$.Num,
      path: [paragraph.$.Num],
      sentences: paragraph.ParagraphSentence
        ? paragraph.ParagraphSentence.map((column: any) => {
            const sentenceObject: sentenceObject[] = column.Sentence
              ? column.Sentence.map((sentence: any, index: number) => {
                  return {
                    num: `${index}`,
                    path: [paragraph.$.Num, index],
                    body: sentence.$ ? sentence._ : sentence,
                  };
                })
              : [];
            return sentenceObject;
          })
        : [],
    };
    return paragraphObject;
  });
};

export const formattingLawAllText = (response: any): Promise<formatLaw> => {
  return new Promise((resolve) => {
    let cleanData = `${response.data}`
      .replace(/<Ruby>/g, "")
      .replace(/<\/Ruby>/g, "")
      .replace(/<Rt>/g, "")
      .replace(/<\/Rt>/g, "");
    cleanData = cleanData.replace(/Subitem.Sentence/g, "ItemSentence");
    XMLParser.parseString(cleanData, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        let rawParagraphs = [];
        if (result.Law.LawBody[0].Preamble) {
          rawParagraphs = result.Law.LawBody[0].Preamble[0].Paragraph;
        }

        if (result.Law.LawBody[0].MainProvision[0].Chapter) {
          const rawChapters = result.Law.LawBody[0].MainProvision[0].Chapter;
          resolve({
            title:
              result.Law.LawBody[0].LawTitle[0]._ ||
              result.Law.LawBody[0].LawTitle[0],
            chapters: formattingChapter(rawChapters),
            preamble: formattingParagraph(rawParagraphs),
            parts: [],
          });
        } else if (result.Law.LawBody[0].MainProvision[0].Part) {
          const rawParts = result.Law.LawBody[0].MainProvision[0].Part;
          const parts = rawParts.map((part: any) => {
            // Chapterを挟まずArticleが直下にくるパターン
            if (!part.Chapter) {
              part.Chapter = [
                {
                  $: {
                    Num: "1",
                  },
                  ChapterTitle: "",
                  Article: part.Article,
                },
              ];
            }
            return {
              num: part.$.Num,
              title: part.PartTitle[0],
              chapters: formattingChapter(part.Chapter),
            };
          });
          resolve({
            title:
              result.Law.LawBody[0].LawTitle[0]._ ||
              result.Law.LawBody[0].LawTitle[0],
            chapters: [],
            preamble: formattingParagraph(rawParagraphs),
            parts: parts,
          });
        } else if (result.Law.LawBody[0].MainProvision[0].Article) {
          // 何も挟まず直接Articleが直下にくるパターン
          const chapters = [
            {
              $: {
                Num: "1",
              },
              ChapterTitle: "",
              Article: result.Law.LawBody[0].MainProvision[0].Article,
            },
          ];
          resolve({
            title:
              result.Law.LawBody[0].LawTitle[0]._ ||
              result.Law.LawBody[0].LawTitle[0],
            chapters: formattingChapter(chapters),
            preamble: formattingParagraph(rawParagraphs),
            parts: [],
          });
        }
      }
    });
  });
};

export const formattingDefinedWordLinks = (
  object: any
): definedWordLinkObject[] => {
  const tmpLinkList: definedWordLinkObject[] = [];
  const findKeys = (object: any, tmpPath: string[]) => {
    Object.keys(object).forEach(async (key) => {
      const path = [...tmpPath];
      if (key !== "citations") {
        if (!isNaN(Number(`${key}`.replace(/_/g, "")))) {
          path.push(`${key}`);
        }
        findKeys(object[key], path);
      } else {
        tmpLinkList.push({
          path: path.join(","),
          definedWords: object[key].sort((a: any, b: any) => {
            if (a.offset < b.offset) return -1;
            if (a.offset > b.offset) return 1;
            return 0;
          }),
        });
      }
    });
  };
  findKeys(object, []);
  return tmpLinkList;
};

export const formattingCitationLinks = (object: any): citationLinkObject[] => {
  const tmpLinkList: citationLinkObject[] = [];
  const findKeys = (object: any, tmpPath: string[]) => {
    Object.keys(object).forEach(async (key) => {
      const path = [...tmpPath];
      if (key !== "citations") {
        if (!isNaN(Number(`${key}`.replace(/_/g, "")))) {
          path.push(`${key}`);
        }
        findKeys(object[key], path);
      } else {
        tmpLinkList.push({
          path: path.join(","),
          citations: object[key].sort((a: any, b: any) => {
            if (a.offset < b.offset) return -1;
            if (a.offset > b.offset) return 1;
            return 0;
          }),
        });
      }
    });
  };
  findKeys(object, []);
  return tmpLinkList;
};

const formattingChapter = (rawChapters: any) => {
  const result = rawChapters.map((chapter: any) => {
    const chapterObject: chapterObject = {
      num: chapter.$.Num,
      title: chapter.ChapterTitle[0],
      sections: chapter.Section
        ? chapter.Section.map((section: any) => {
            const sectionObject: sectionObject = {
              num: section.$.Num,
              title: section.SectionTitle[0],
              articles: section.Article
                ? getArticlesObject(section.Article)
                : [],
              subsections: section.Subsection
                ? section.Subsection.map((subSection: any) => {
                    const sectionObject: sectionObject = {
                      num: subSection.$.Num,
                      title: subSection.SubsectionTitle[0],
                      articles: subSection.Article
                        ? getArticlesObject(subSection.Article)
                        : [],
                      divisions: subSection.Division
                        ? subSection.Division.map((division: any) => {
                            const divisionObject: divisionObject = {
                              num: division.$.Num,
                              title: division.DivisionTitle[0],
                              articles: division.Article
                                ? getArticlesObject(division.Article)
                                : [],
                            };
                            return divisionObject;
                          })
                        : [],
                    };
                    return sectionObject;
                  })
                : [],
            };
            return sectionObject;
          })
        : [],
      articles: chapter.Article ? getArticlesObject(chapter.Article) : [],
    };
    return chapterObject;
  });
  return result;
};

export const getSentenceBodyObjectHierarchy = (
  sentence: sentenceObject,
  linkList: LinkListMap,
  isSetLink: boolean,
  lawId?: string
): sentenceBodyObjectHierarchy[] => {
  let bodyObject: sentenceBodyObject[] = [
    {
      body: sentence.body,
    },
  ];

  let targetCitations = linkList.get(`${sentence.path}`);

  // TODO: sentenceが複数並ぶ場合に defined_words/citation_positions のインデックスが1から始まる（ずれている）問題をフロント側で無理やり対応
  if (sentence.path.length === 3 && sentence.path[2] === 1) {
    targetCitations = undefined;
  } else if (
    !targetCitations &&
    sentence.path.length === 3 &&
    sentence.path[2] === 0
  ) {
    targetCitations = linkList.get(`${sentence.path}`.replace(/0$/, "1"));
  }

  if (targetCitations && isSetLink) {
    bodyObject = [];
    let lastIndex = 0;
    targetCitations.citations?.forEach((citation, index) => {
      if (index === 0 && citation.offset !== 0) {
        const word = sentence.body.slice(0, citation.offset);
        bodyObject.push({
          body: word,
        });
        lastIndex = citation.offset;
      }
      if (citation.offset > lastIndex) {
        const word = sentence.body.slice(lastIndex, citation.offset);
        bodyObject.push({
          body: word,
        });
        lastIndex = citation.offset;
      }
      const word = sentence.body.slice(
        citation.offset,
        citation.offset + citation.length
      );
      bodyObject.push({
        body: word,
        to_law_id: citation.to_law_id,
        to_provision_id: citation.to_provision_id,
        to_article_num: citation.to_article_num,
        to_paragraph_id: citation.to_paragraph_id,
        defined_word_id: citation.defined_word_id,
        offset: citation.offset,
        length: citation.length,
      });
      lastIndex = citation.offset + citation.length;
      if (targetCitations && index + 1 === targetCitations.citations?.length) {
        const word = sentence.body.slice(lastIndex);
        bodyObject.push({
          body: word,
        });
      }
    });
  }

  const parenthesesNestBodyObject: sentenceBodyObjectHierarchy[] = [];
  let setTargetObject: sentenceBodyObjectHierarchy[] =
    parenthesesNestBodyObject;
  let parentObject: sentenceBodyObjectHierarchy[] = parenthesesNestBodyObject;
  bodyObject.forEach((object, index) => {
    if (
      object.to_law_id === undefined &&
      object.defined_word_id === undefined
    ) {
      if (object.body && /（|）/.test(object.body)) {
        object.body.split(/(（|）)/).forEach((judgeWord) => {
          if (judgeWord === "（") {
            const grandObject = parentObject;
            parentObject = setTargetObject;
            setTargetObject = setTargetObject[setTargetObject.length - 1].child;
            setTargetObject.push({
              parent: {
                body: judgeWord,
              },
              child: [],
              getParent: () => {
                return grandObject;
              },
            });
          } else if (judgeWord === "）") {
            setTargetObject.push({
              parent: {
                body: judgeWord,
              },
              child: [],
            });
            const parentTop = setTargetObject[0];
            setTargetObject = parentObject;
            if (parentTop.getParent) {
              parentObject = parentTop.getParent();
            }
          } else {
            setTargetObject.push({
              parent: {
                body: judgeWord,
              },
              child: [],
            });
          }
        });
      } else {
        setTargetObject.push({
          parent: object,
          child: [],
        });
      }
    } else {
      setTargetObject.push({
        parent: object,
        child: [],
      });
    }
  });
  return parenthesesNestBodyObject;
};
