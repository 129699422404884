/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from "react";
import { Typography, Box, Drawer, IconButton, Link } from "@mui/material";
import { citationSourcesListObject } from "../../interface/LawInterface";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  citationSourcesDrawerState: boolean;
  sourcesArticle: string;
  citationSources: citationSourcesListObject;
  linkArticleTitle: string;
  setCitationSourcesDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CitationSourcesDrawer: React.VFC<Props> = ({
  citationSourcesDrawerState,
  sourcesArticle,
  citationSources,
  linkArticleTitle,
  setCitationSourcesDrawerState,
}) => {
  const openNewWindow = (name = linkArticleTitle, id?: string) => {
    let path = `/law/${name}`;
    if (id) {
      path += `#${id}`;
    }
    window.open(path);
  };

  const formatArticleNum = (article_num: string): string => {
    if (article_num === undefined) {
      return "";
    }
    if (article_num.indexOf("_") > 0) {
      const formatTitle = `第${article_num.replace("_", "条の")}`;
      return formatTitle.replace(/_/g, "の");
    } else {
      return `第${article_num}条`;
    }
  };

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={citationSourcesDrawerState}
    >
      <Box
        pt={4}
        px={4}
        sx={{
          top: "60%",
        }}
      >
        <IconButton
          sx={{
            position: "fixed",
            right: 10,
            top: "calc(50% + 16px)",
            backgroundColor: "#FFF",
          }}
          onClick={() => setCitationSourcesDrawerState(false)}
        >
          <CloseIcon />
        </IconButton>
        {sourcesArticle in citationSources.article_num &&
          Object.keys(
            citationSources.article_num[sourcesArticle].paragraph_id
          ).map((key) => {
            const paragraph =
              citationSources.article_num[sourcesArticle].paragraph_id[key];
            return (
              <Box key={`${sourcesArticle}_${key}`} sx={{ mb: 2 }}>
                <Typography variant="h6" paragraph>
                  {formatArticleNum(sourcesArticle)}
                  {key}項を参照している法律の一覧
                </Typography>
                {paragraph.citation_sources.map((citation_source: any) => {
                  return (
                    <>
                      {citation_source.map((item: any, index: number) => {
                        return (
                          <>
                            {item && (
                              <Box
                                key={`${item.from_article_num}_${item.from_paragraph_id}_${index}`}
                                sx={{ mb: 0.5 }}
                              >
                                <Link
                                  variant="body1"
                                  onClick={() =>
                                    openNewWindow(
                                      item.from_law_name,
                                      `${item.from_article_num},${item.from_paragraph_id}`
                                    )
                                  }
                                >
                                  {item.from_law_name}{" "}
                                  {formatArticleNum(item.from_article_num)} 第
                                  {item.from_paragraph_id}項
                                </Link>
                              </Box>
                            )}
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </Box>
            );
          })}
      </Box>
    </Drawer>
  );
};
