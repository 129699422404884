import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
// import { padding } from '@mui/system';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    h6: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          width: "auto",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.85rem",
          padding: 8,
          lineHeight: 1.5,
        },
        popper: {
          cursor: "pointer",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          fontSize: "1rem",
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          padding: 0,
          lineHeight: 1,
        },
        outlinedSizeSmall: {
          minWidth: "52px",
          fontSize: "0.7125rem",
          paddingTop: "1px",
          paddingBottom: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          top: "50%",
        },
      },
    },
  },
});

export default theme;
