import React from "react";
import { Link } from "@mui/material";

interface Props {
  body: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  onMouseOver: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
}

export const ReferenceWord: React.VFC<Props> = ({
  body,
  onClick,
  onMouseOver,
}) => {
  return (
    <Link
      variant="body1"
      underline="hover"
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      {body}
    </Link>
  );
};
