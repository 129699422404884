import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  articleObject,
  chapterObject,
  partObject,
} from "../interface/LawInterface";
const filter = createFilterOptions<JumpListType>();

interface Props {
  lawChapters: chapterObject[];
  lawParts: partObject[];
}

interface JumpListType {
  title: string;
  search?: string;
  link?: string;
}

export const LawJumpSuggest: React.VFC<Props> = ({ lawChapters, lawParts }) => {
  const [value, setValue] = React.useState<JumpListType | null>(null);
  const JumpLists: JumpListType[] = [];

  const linkPage = (id: string | undefined) => {
    if (id) {
      window.location.href = `#${id}`;
    }
  };

  const formatArticleNum = (articleNum: string): string => {
    if (articleNum.indexOf("_") > 0) {
      const formatTitle = `第${articleNum.replace("_", "条の")}`;
      return formatTitle.replace(/_/g, "の");
    } else {
      return `第${articleNum}条`;
    }
  };

  const pushArticlesJumpLists = (articles: articleObject[]) => {
    articles?.forEach((article) => {
      JumpLists.push({
        title: `${formatArticleNum(article.num)} ${article.caption}`,
        search: `${article.num} ${article.caption}`,
        link: `${article.title}${article.caption}`,
      });
    });
  };

  const pushChaptersJumpLists = (chapters: chapterObject[]) => {
    chapters.forEach((chapter) => {
      chapter.sections?.forEach((section) => {
        if (section.articles) {
          pushArticlesJumpLists(section.articles);
        }
        section.subsections?.forEach((subSection) => {
          if (subSection.articles) {
            pushArticlesJumpLists(subSection.articles);
          }
        });
      });
      if (chapter.articles) {
        pushArticlesJumpLists(chapter.articles);
      }
    });
  };

  lawParts.forEach((part) => {
    if (part.chapters) {
      pushChaptersJumpLists(part.chapters);
    }
  });
  pushChaptersJumpLists(lawChapters);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else {
          if (newValue) {
            linkPage(newValue.link);
          }
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        params.inputValue = params.inputValue
          .replace("_", "条の")
          .replace("-", "条の");
        return filter(options, params);
      }}
      autoHighlight
      options={JumpLists}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.title;
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          onClick={() => {
            linkPage(option.link);
          }}
        >
          {option.title}
        </li>
      )}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="条文、見出し検索" />
      )}
    />
  );
};
