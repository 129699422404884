import React, { Dispatch, SetStateAction } from "react";
import { Typography, Button } from "@mui/material";
import { InView } from "react-intersection-observer";

interface Props {
  articleNum: string;
  title: string;
  isSetLink?: boolean;
  setCitationSourcesDrawerState?: Dispatch<SetStateAction<boolean>>;
  setSourcesArticle?: Dispatch<SetStateAction<string>>;
  setSourcesParagraph?: Dispatch<SetStateAction<string>>;
  addCitationSources?: (addArticleNum: string) => Promise<void>;
}

export const LawArticlesTitle: React.VFC<Props> = ({
  articleNum,
  title,
  isSetLink = true,
  setCitationSourcesDrawerState,
  setSourcesArticle,
  setSourcesParagraph,
  addCitationSources,
}) => {
  const clickFormatQuote = (articleNum: string) => {
    if (
      setCitationSourcesDrawerState &&
      setSourcesArticle &&
      setSourcesParagraph
    ) {
      setCitationSourcesDrawerState(true);
      setSourcesArticle(articleNum);
      setSourcesParagraph("1");
    }
  };

  return (
    <InView
      as="div"
      onChange={(inView) => {
        if (isSetLink && inView && addCitationSources) {
          addCitationSources(articleNum);
        }
      }}
      triggerOnce={true}
    >
      <Typography
        variant="h6"
        sx={
          isSetLink
            ? {
                mb: 1,
                position: "sticky",
                top: 64,
                backgroundColor: "#FFF",
                py: 2,
              }
            : {
                mb: 1,
              }
        }
        id={title}
      >
        {title}
        {isSetLink && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => clickFormatQuote(articleNum)}
            sx={{
              mx: 1,
            }}
          >
            被参照
          </Button>
        )}
      </Typography>
    </InView>
  );
};
