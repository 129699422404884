import React from "react";
import { Link, Tooltip } from "@mui/material";

interface Props {
  body: string;
  bodyOnClick: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  tooltipBody: string;
  tooltipOnClick: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  tooltipOnMouseOver: React.MouseEventHandler<HTMLDivElement>;
}

export const DefinitionWord: React.VFC<Props> = ({
  body,
  bodyOnClick,
  tooltipBody,
  tooltipOnClick,
  tooltipOnMouseOver,
}) => {
  return (
    <Tooltip
      onMouseOver={tooltipOnMouseOver}
      title={
        <Link color="inherit" underline="hover" onClick={tooltipOnClick}>
          {tooltipBody}
        </Link>
      }
    >
      <Link variant="body1" underline="hover" onClick={bodyOnClick}>
        {body}
      </Link>
    </Tooltip>
  );
};
