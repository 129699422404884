import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { DefinitionWord } from "./DefinitionWord";
import { ReferenceWord } from "./ReferenceWord";
import { HighLightWord } from "./HighLightWord";
import {
  sentenceObject,
  definedWordSauceObject,
  sentenceBodyObject,
  LinkListMap,
  sentenceBodyObjectHierarchy,
} from "../interface/LawInterface";
import { getSentenceBodyObjectHierarchy } from "../repositories/Formatting";

interface Props {
  sentences: sentenceObject[];
  linkList: LinkListMap;
  isSetLink: boolean;
  definedWordSauces: definedWordSauceObject[];
  setToLawLinkDrawer: (
    sentenceBody: sentenceBodyObject,
    sourceBody?: string
  ) => Promise<void>;
  linkDrawerSourceBody: string;
  lawId?: string;
}

export const LawSentences: React.VFC<Props> = ({
  sentences,
  linkList,
  isSetLink = true,
  definedWordSauces,
  setToLawLinkDrawer,
  linkDrawerSourceBody,
  lawId,
}) => {
  const getDefinedWord = (id: number): definedWordSauceObject | false => {
    const target = definedWordSauces.find((v) => v.id === id);
    return target || false;
  };

  const linkDrawerHighlight = (
    rawBody: string,
    sourceBody: string
  ): JSX.Element[] => {
    const resultJsx: JSX.Element[] = [];
    rawBody = rawBody.replace(
      new RegExp(`${sourceBody}`, "g"),
      `,${sourceBody},`
    );
    rawBody.split(",").forEach((body) => {
      resultJsx.push(
        <span className={body === sourceBody ? "linkDrawerHighlight" : ""}>
          {body}
        </span>
      );
    });
    return resultJsx;
  };

  const wordScroll = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (event.screenY > window.innerHeight / 2) {
      window.scrollTo({
        top: event.pageY - window.innerHeight / 2 + 20,
        behavior: "smooth",
      });
    }
  };

  const renderSentence = (
    sentence: sentenceObject,
    bodyObject: sentenceBodyObjectHierarchy[]
  ): JSX.Element[] => {
    return bodyObject.map((object: sentenceBodyObjectHierarchy, index) => {
      const objectParent = object.parent;
      if (objectParent.to_law_id) {
        // 参照
        return (
          <ReferenceWord
            key={`${sentence.path.join("_")}-${index}`}
            body={objectParent.body || ""}
            onClick={(event) => {
              setToLawLinkDrawer(objectParent);
              wordScroll(event);
            }}
            onMouseOver={() => {
              console.log(
                `[参照] :\noffset: ${objectParent.offset}\nlength: ${objectParent.length}\nto_law_id: ${objectParent.to_law_id}\nto_article_num: ${objectParent.to_article_num}\nto_paragraph_id: ${objectParent.to_paragraph_id}\ndefined_word_id: ${objectParent.defined_word_id}\n`
              );
              console.log(objectParent);
            }}
          />
        );
      } else if (objectParent.defined_word_id) {
        // 定義語
        const definedWord = getDefinedWord(objectParent.defined_word_id);
        if (definedWord) {
          const definitionWordSetToLawLinkDrawer = () => {
            setToLawLinkDrawer(
              {
                to_law_id: definedWord.law_id,
                to_article_num: definedWord.article_num,
                to_paragraph_id: definedWord.paragraph_id,
              },
              objectParent.body
            );
          };
          return (
            <DefinitionWord
              key={`${sentence.path.join("_")}-${index}`}
              body={objectParent.body || ""}
              bodyOnClick={(event) => {
                definitionWordSetToLawLinkDrawer();
                wordScroll(event);
              }}
              tooltipBody={definedWord.def}
              tooltipOnClick={definitionWordSetToLawLinkDrawer}
              tooltipOnMouseOver={() => {
                console.log(
                  `[定義語] :\noffset: ${objectParent.offset}\nlength: ${objectParent.length}\ndefined_word_id: ${objectParent.defined_word_id}\n`
                );
                console.log(objectParent);
              }}
            />
          );
        } else {
          return <></>;
        }
      } else {
        // () で囲われている場合のhover
        return (
          <span key={`${sentence.path.join("_")}-${index}`}>
            {linkDrawerSourceBody !== "" &&
            objectParent.body &&
            objectParent.body.indexOf(linkDrawerSourceBody) > 0 ? (
              linkDrawerHighlight(objectParent.body, linkDrawerSourceBody)
            ) : (
              <>{objectParent.body}</>
            )}
            {object.child && object.child.length > 0 && (
              <HighLightWord child={renderSentence(sentence, object.child)} />
            )}
          </span>
        );
      }
    });
  };

  return useMemo(() => {
    return (
      <>
        {sentences
          ? sentences.map((sentence: any) => {
              if (sentence.length) {
                return sentence.map((item: sentenceObject) => {
                  return (
                    <Box key={item.path.join("_")} component="span">
                      {renderSentence(
                        item,
                        getSentenceBodyObjectHierarchy(
                          item,
                          linkList,
                          isSetLink,
                          lawId
                        )
                      )}
                    </Box>
                  );
                });
              } else {
                return (
                  <Box key={sentence.path.join("_")}>
                    {renderSentence(
                      sentence,
                      getSentenceBodyObjectHierarchy(
                        sentence,
                        linkList,
                        isSetLink,
                        lawId
                      )
                    )}
                  </Box>
                );
              }
            })
          : ""}
      </>
    );
  }, [definedWordSauces, linkDrawerSourceBody]);
};
