/* eslint-disable no-labels */
/* eslint-disable camelcase */
import axios from "axios";
import Bottleneck from "bottleneck";
import {
  articleObject,
  citationPositionObject,
  definedWordSauceObject,
  citationSourcesListObject,
  sentenceBodyObject,
  formatLaw,
} from "../interface/LawInterface";
import { getTargetArticles, formattingLawAllText } from "./Formatting";

import { db } from "./db";

const apiBaseURL = "/citation_api/laws";
const apiInfoURL = "/citation_api/info";

const limiter = new Bottleneck({
  maxConcurrent: 10,
});

export const checkUpdate = (): Promise<boolean> => {
  const localStorageName = "updateAt";
  const localStorageUpdateAt = localStorage.getItem(localStorageName);
  return new Promise((resolve) => {
    axios
      .get(apiInfoURL, {
        headers: { "Content-Type": "application/xml; charset=utf-8" },
      })
      .then(async (response: any) => {
        let result = false;
        if (localStorageUpdateAt) {
          const localUpdateAt = new Date(JSON.parse(localStorageUpdateAt));
          const apiUpdateAt = new Date(response.data.update_datetime);
          if (apiUpdateAt > localUpdateAt) {
            result = true;
          }
        }
        localStorage.setItem(
          localStorageName,
          JSON.stringify(response.data.update_datetime)
        );
        resolve(result);
      });
  });
};

export const getLawId = (lawName: string): Promise<string> => {
  return new Promise((resolve) => {
    const localStorageName = `${lawName}_law_id`;
    const localStorageRaw = localStorage.getItem(localStorageName);
    if (localStorageRaw) {
      const localStorageFormatData: string = JSON.parse(localStorageRaw);
      resolve(localStorageFormatData);
    } else {
      limiter.schedule(() =>
        axios
          .get(`${apiBaseURL}/${lawName}/law_id`, {
            headers: { "Content-Type": "application/xml; charset=utf-8" },
          })
          .then(async (response: any) => {
            try {
              localStorage.setItem(
                localStorageName,
                JSON.stringify(response.data.law_id)
              );
            } catch (error) {
              console.log(error);
            }
            resolve(response.data.law_id);
          })
      );
    }
  });
};

export const getLawAllTextFromLawName = async (
  lawName: string
): Promise<formatLaw> => {
  const lawId = await getLawId(lawName);
  const table = db.allText;
  const localStorageLaw = await table.where("name").equals(lawId).toArray();

  return new Promise((resolve) => {
    if (localStorageLaw.length > 0) {
      const localStorageFormatLaw = localStorageLaw[0].object as formatLaw;
      resolve({
        title: localStorageFormatLaw.title,
        chapters: localStorageFormatLaw.chapters,
        preamble: localStorageFormatLaw.preamble,
        parts: localStorageFormatLaw.parts,
      });
    } else {
      limiter.schedule(() =>
        axios
          .get(`${apiBaseURL}/${lawName}`, {
            headers: { "Content-Type": "application/xml; charset=utf-8" },
          })
          .then(async (response) => {
            const formatLawAllText = await formattingLawAllText(response);
            try {
              table.add({ name: lawId, object: formatLawAllText });
            } catch (error) {
              console.log(error);
            }
            resolve({
              title: formatLawAllText.title,
              chapters: formatLawAllText.chapters,
              preamble: formatLawAllText.preamble,
              parts: formatLawAllText.parts,
            });
          })
      );
    }
  });
};

export const getToLawArticle = (
  object: sentenceBodyObject
): Promise<{
  title: string;
  article: articleObject;
}> => {
  return new Promise((resolve) => {
    if (object.to_law_id) {
      const localStorageLaw = localStorage.getItem(`${object.to_law_id}`);
      if (localStorageLaw) {
        const localStorageLawJson = JSON.parse(localStorageLaw);
        resolve({
          title: localStorageLawJson.title,
          article: getTargetArticles(
            localStorageLawJson,
            object.to_article_num
          ),
        });
      } else {
        limiter.schedule(() =>
          axios
            .get(`${apiBaseURL}/${object.to_law_id}?is_law_id=true`, {
              headers: { "Content-Type": "application/xml; charset=utf-8" },
            })
            .then(async (response) => {
              const formatLawAllText = await formattingLawAllText(response);
              try {
                localStorage.setItem(
                  `${object.to_law_id}`,
                  JSON.stringify(formatLawAllText)
                );
              } catch (error) {
                console.log(error);
              }
              resolve({
                title: formatLawAllText.title,
                article: getTargetArticles(
                  formatLawAllText,
                  object.to_article_num
                ),
              });
            })
        );
      }
    }
  });
};

export const getDefinedWordLinks = async (
  lawName: string
): Promise<citationPositionObject> => {
  const lawId = await getLawId(lawName);
  const table = db.definedWordsCitationPositions;
  const localStorageLaw = await table.where("name").equals(lawId).toArray();

  return new Promise((resolve) => {
    if (localStorageLaw.length > 0) {
      const localStorageFormatLaw = localStorageLaw[0]
        .object as citationPositionObject;
      resolve(localStorageFormatLaw);
    } else {
      limiter.schedule(() =>
        axios
          .get(`${apiBaseURL}/${lawName}/defined_words/citation_positions`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
          })
          .then((response) => {
            try {
              table.add({ name: lawId, object: response.data });
            } catch (error) {
              console.log(error);
            }
            resolve(response.data);
          })
      );
    }
  });
};

export const getCitationLinks = async (
  lawName: string
): Promise<citationPositionObject> => {
  const lawId = await getLawId(lawName);
  const table = db.lawItemsCitationPositions;
  const localStorageLaw = await table.where("name").equals(lawId).toArray();

  return new Promise((resolve) => {
    if (localStorageLaw.length > 0) {
      const localStorageFormatLaw = localStorageLaw[0]
        .object as citationPositionObject;
      resolve(localStorageFormatLaw);
    } else {
      limiter.schedule(() =>
        axios
          .get(`${apiBaseURL}/${lawName}/law_items/citation_positions`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
          })
          .then((response) => {
            try {
              table.add({ name: lawId, object: response.data });
            } catch (error) {
              console.log(error);
            }
            resolve(response.data);
          })
      );
    }
  });
};

export const getDefinedWordSauces = async (
  lawName: string
): Promise<definedWordSauceObject[]> => {
  const lawId = await getLawId(lawName);
  const table = db.definedWords;
  const localStorageLaw = await table.where("name").equals(lawId).toArray();

  return new Promise((resolve) => {
    if (localStorageLaw.length > 0) {
      const localStorageFormatLaw = localStorageLaw[0]
        .object as definedWordSauceObject[];
      resolve(localStorageFormatLaw);
    } else {
      limiter.schedule(() =>
        axios
          .get(`${apiBaseURL}/${lawName}/defined_words`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
          })
          .then((response) => {
            try {
              table.add({ name: lawId, object: response.data });
            } catch (error) {
              console.log(error);
            }
            resolve(response.data);
          })
      );
    }
  });
};

export const getCitationSources = async (
  lawName: string,
  provisionId: string = "0",
  articleNum: string = "0"
): Promise<citationSourcesListObject> => {
  const lawId = await getLawId(lawName);
  const table = db.citationSources;
  const name = `${lawId}_${provisionId}_${articleNum}`;
  const localStorageLaw = await table.where("name").equals(name).toArray();

  return new Promise((resolve) => {
    if (localStorageLaw.length > 0) {
      const localStorageFormatLaw = localStorageLaw[0]
        .object as citationSourcesListObject;
      resolve(localStorageFormatLaw);
    } else {
      let path = `${lawName}/law_items/citation_sources`;
      if (articleNum !== "0") {
        path += `?provision_id=${provisionId}&article_num=${articleNum}`;
      }
      limiter.schedule(() =>
        axios
          .get(`${apiBaseURL}/${path}`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
          })
          .then((response) => {
            try {
              table.add({ name: name, object: response.data });
            } catch (error) {
              console.log(error);
            }
            resolve(response.data);
          })
      );
    }
  });
};

export const postLawSearch = async (searchWords: string): Promise<string[]> => {
  return new Promise((resolve) => {
    limiter.schedule(() =>
      axios
        .post(
          `${apiBaseURL}?action=search`,
          {
            keyword_list: searchWords.split(" "),
          },
          {
            headers: { "Content-Type": "application/json; charset=utf-8" },
          }
        )
        .then((response: any) => {
          resolve(response.data.laws);
        })
    );
  });
};
