import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-padding-top: 100px;
  }
  .hoverParentheses:hover,
  .sentenceParenthesesHover,
  .linkDrawerHighlight{
    display: inline;
    background-color: #f6f9c8;
  }
  .hoverParentheses,
  .hoverParentheses div,
  .inline{
    display: inline;
  }
  a {
    cursor: pointer;
  }

  #searchLaw .MuiOutlinedInput-root{
    background-color: #f5f5fa;
  }
`;
